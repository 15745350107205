.msg-t-10[data-v-706cfb49] {
  font-size: 12px;
  margin-top: 10px;
}
.time-box[data-v-706cfb49] {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}
.content[data-v-706cfb49] {
  margin-left: 60px;
  border-left: 3px solid #e6e6e6;
  padding-left: 30px;
  position: relative;
  padding-bottom: 50px;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  min-height: 80px;
  margin-right: 10px;
}
.content2[data-v-706cfb49] {
  border-left: 3px dashed #e6e6e6;
}
.time-box:last-child .content[data-v-706cfb49] {
  border-left: 2px solid transparent;
}
.shangyi[data-v-706cfb49] {
  padding-top: 20px;
  margin-left: 10px;
}
.yuan[data-v-706cfb49] {
  position: absolute;
  top: 0;
  left: 0px;
  display: inline-block;
  width: 60px;
  height: 60px;
  background: #fff;
  padding: 10px;
  border-radius: 50%;
  -webkit-transform: translateX(-56%);
          transform: translateX(-56%);
}
.yuan2[data-v-706cfb49] {
  position: absolute;
  top: 50px;
  left: 5px;
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
}
.title[data-v-706cfb49] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  font-size: 14px;
  font-weight: bold;
  color: #000;
}
.title .time[data-v-706cfb49] {
  color: #999999;
  font-weight: 400;
}
.nodeError[data-v-706cfb49] {
  color: #f56c6c;
}
.nodePrimary[data-v-706cfb49] {
  color: #409eff;
}
.nodeSuccess[data-v-706cfb49] {
  color: #67c23a;
}
.nodeWarn[data-v-706cfb49] {
  color: #e6a23c;
}
.linePrimary[data-v-706cfb49] {
  color: #409eff;
}
.lineSuccess[data-v-706cfb49] {
  color: #67c23a;
}
.lineWarn[data-v-706cfb49] {
  color: #e6a23c;
}
.lineError[data-v-706cfb49] {
  color: #f56c6c;
}