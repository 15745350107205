.custom-time-line[data-v-706cfb49] .el-timeline-item__tail {
  left: 7px;
  border-left-color: #0070ff;
}
.custom-time-line[data-v-706cfb49] .el-timeline-item__node--normal {
  width: 18px;
  height: 18px;
}
.custom-time-line[data-v-706cfb49] .el-timeline-item__node {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAAAXNSR0IArs4c6QAAAbpJREFUOE+llMtKW1EYhb8/JlGCqK046CzWS6o4clToEzguCG2kdCRSUQM+hyAoDjoSqS0UHPcJCn0CjdVqOurItN6Cl2T/sve5GqOJdI/O2Wfv76y1zllbaDTmtItrnpKmkxppt6SNK644w/CXj3Jcv01uTcxpOwmyKJ0NXxBMVqlQ4ZB1uQimIlBeu+jjOYa2ByHBQ8VQ5hebcmKnPJBVAqMICXdv7SSZRJgAss6Y8BPDN6psOZt2JKjxj6JV5oE+6AhJMu5aeIaw4gPuilNK9tUof9xDwzmrUhSmtZsOBkMlKT7fC4lslajyNlSm7AkzmiVNr6/mDcJiixkto3zyLR4J8zoG2IysrQ2E0ZZAsIsh76+9FGZ13EXphfcDSLYIusTwyo9E60HfQ3XNaVUMLyNQ3FqCTSDXnAEo2yjvImsFzWLCsKcQCi2CllC+RGHbXglD//X5L9j3Ql7QXNivx/6QtndrsuOB3msHPbwIe+ZV5DUwgTDsSgMl1FXka+xHNJyyE1XEwqxFGAj71iwo27MaB6zESxtsssoy9Ie9uw8mnHHM78bHSHyT7V+GJ5gGB1uKcqAivuUGsfibiSBgCeEAAAAASUVORK5CYII=);
}
.custom-time-line[data-v-706cfb49] .el-timeline-item__wrapper {
  padding-left: 66px;
}